.navbarContainer nav {
  padding: 0 64px;
  background-color: transparent;
  box-shadow: none;

  i,
  li a,
  .logoContainer {
    cursor: none;
    color: black;
  }

  .logoContainer {
    height: 100%;
    display: flex;
    align-items: center;

    h6 {
      transition-duration: 0.5s;
    }

    &:hover h6 {
      transform: scale(1.04);
      transition-duration: 0.5s;
    }
  }

  ul {
    a {
      cursor: none;
      position: relative;
      display: inline;
      padding: 4px 16px;

      &:hover {
        background-color: transparent;
      }

      &::before {
        content: " ";
        height: 50%;
        width: 80%;
        display: block;
        position: absolute;
        top: 50%;
        left: 10%;
        background: rgba(114, 179, 116, 0.2);
        z-index: -1;
        transform: scaleX(0);
        transform-origin: bottom right;
        transition: transform 0.3s ease-in;
      }

      &:hover::before {
        transform: scaleX(1);
        transform-origin: bottom left;
      }
    }
  }
}

@media (max-width: 992px) {
  .navbarContainer .nav {
    padding: 0 0;
  }
}

@use "../style/colors.scss" as c;
.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 64px;

  .scrollText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    color: c.$grey;
    margin-bottom: 64px;
    text-align: center;

    i {
      color: c.$green;
      margin-top: 24px;
      animation: bobble 1s ease-in;
      animation-iteration-count: infinite;
    }
  }

  .bigText {
    text-transform: uppercase;
    z-index: 2;
    font-size: 160px;
    position: fixed;
    top: calc(100% - 180px);
    right: 8px;
    font-weight: 800;
    color: c.$green-alpha;
    animation: fadeIn 0.5s ease-in;
  }
}

@media (max-width: 992px) {
  .container {
    padding: 48px 16px;

    .bigText {
      top: calc(100% - 150px);
      font-size: 130px;
    }

    .row {
      margin-top: 140px;
    }
  }
}

@media (max-width: 768px) {
  .container {
    .bigText {
      top: calc(100% - 120px);
      font-size: 100px;
    }

    .row {
      margin-top: 148px;
    }
  }
}

@media (max-width: 480px) {
  .container {
    .row {
      margin-top: 148px;
    }
  }
}

@keyframes bobble {
  50% {
    transform: translate(0px, 8px);
  }
  to {
    transform: initial;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideUp {
  from {
    transform: scaleY(0);
  }
  to {
    transform: scaleY(1);
  }
}
@keyframes slideOut {
  from {
    transform: scaleY(1);
  }
  to {
    transform: scaleY(0);
  }
}

@use "../../style/colors.scss" as c;

div.experience {
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-wrap: wrap;
  width: 60%;
  border: solid 1.5px c.$green;
  border-radius: 10px;
  margin-bottom: 100px;
  scroll-margin-top: 64px;

  h5 {
    margin-bottom: 8px;
  }

  &:hover img {
    transform: scale(1.35) rotate(3deg);
    transition-duration: 0.5s;
  }

  .date {
    text-transform: uppercase;
    margin-bottom: 20px;
  }

  .imgCol {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 0;
    height: calc(100%);
    min-height: 400px;

    img {
      width: 80%;
      max-height: 300px;
      object-fit: contain;
      object-position: center;
      transition-duration: 0.8s;
      border-radius: 10px;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 48px;
  }

  .contentText {
    width: 100%;
  }

  .link {
    color: white;
    cursor: none;
  }
}

@media (max-width: 992px) {
  div.experience {
    .content {
      padding: 48px 24px;
      padding-bottom: 0;
    }

    &:hover img {
      transform: scale(1.15) rotate(3deg);
      transition-duration: 0.5s;
    }
  }
}

@media (max-width: 768px) {
  div.experience {
    width: 100%;

    .content {
      padding: 48px 24px;
      padding-bottom: 0;
    }
  }
}

@media (max-width: 498px) {
  div.experience:hover img {
    transform: none;
  }
}

@use "./colors.scss" as c;

//desktop sizes
$h1-size: 2.986rem;
$h2-size: 2.488rem;
$h3-size: 2.074rem;
$h4-size: 1.728rem;
$h5-size: 1.44rem;
$h6-size: 1.2rem;
$p-size: 1rem;
$caption-size: 0.833rem;

body,
html,
#root {
  height: 100%;
  font-size: 16px;
}
@media (max-width: 480px) {
  html,
  #root {
    font-size: 14px;
  }
}

body {
  margin: 0;
  font-family: unquote("Lato") !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin: 0;
    letter-spacing: 2%;
    line-height: auto;
  }
  h1 {
    font-size: $h1-size !important;
    font-weight: 800 !important;
    line-height: 1.2;
  }
  h2 {
    font-size: $h2-size !important;
    font-weight: 600 !important;
    line-height: 1.2;
  }
  h3 {
    font-size: $h3-size !important;
    font-weight: 600 !important;
    line-height: 1.2;
  }
  h4 {
    font-size: $h4-size !important;
    font-weight: 400 !important;
    line-height: 1.2;
  }
  h5 {
    font-size: $h5-size !important;
    font-weight: 400 !important;
    line-height: 1.2;
  }
  h6 {
    font-size: $h6-size !important;
    font-weight: 800;
    line-height: 1.2;
  }
  p {
    font-size: $p-size !important;
    font-weight: normal;
  }
  p.caption {
    font-size: $caption-size !important;
    font-weight: 400;
  }
  a {
    font-size: $caption-size;
    color: c.$green-solid;
    font-weight: 400;
  }
  button,
  a {
    cursor: none !important;
    font-family: unquote("Lato") !important;
  }
}

div.tile-2 {
  position: relative;
  overflow: visible !important;

  img {
    position: relative;
  }
}

// cursor styles
body {
  cursor: none;
}

.cursor {
  width: 25px;
  height: 25px;
  position: absolute;
  border-radius: 50%;
  background-color: c.$green;
  transition: transform 0.5s;
  pointer-events: none;
  mix-blend-mode: difference;
}

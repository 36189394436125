@use "../style/colors.scss" as c;

.container {
  width: 100%;
  padding: 0 64px;

  &::before {
    content: " ";
    height: 30%;
    width: 105%;
    display: block;
    position: absolute;
    border-radius: 100% 0 0 0;
    bottom: 0;
    left: -5%;
    background: c.$green-alpha-2;
    z-index: -1;
  }

  .socials {
    border-top: 2px solid c.$green;
    margin-top: 24px;
    padding-top: 24px;

    p {
      color: c.$grey;
    }

    img {
      width: 18px;
    }

    a {
      position: relative;
      display: inline;
      padding: 4px 16px;
      color: black;
    }
  }

  .row {
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 0;

    img {
      max-height: 85vh;
    }

    .illustration {
      margin: 28px 0;
      text-align: center;
    }

    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      h1 {
        position: relative;
        width: max-content;
        color: c.$grey;

        &::before {
          content: " ";
          height: 50%;
          width: 110%;
          display: block;
          position: absolute;
          top: 70%;
          left: -5%;
          background: c.$green-alpha;
          z-index: -1;
          animation: underline 0.7s normal;
          animation-iteration-count: 1;
          transform-origin: bottom left;
          transition: transform 1s ease-in;
        }
      }

      h6 {
        font-weight: normal;
        margin-top: 48px;
        max-width: 550px;
      }

      p {
        margin-top: 64px;
      }
    }
  }

  .caption {
    text-align: center;
    padding-bottom: 24px;
    color: c.$grey;
  }
}

@media (max-width: 580px) {
  .container {
    padding: 0 8px;

    .row .content {
      margin-top: 24px;

      .colContent {
        h1 {
          left: 50%;
          right: 0;
          transform: translateX(-50%);
        }
        h6,
        p {
          text-align: center;
        }
      }
    }

    .socials {
      display: flex;
      justify-content: center;
    }

    .caption {
      padding-top: 24px;
    }
  }
}

@keyframes underline {
  from {
    transform: scaleX(0);
  }
  to {
    transform: scaleX(1);
  }
}

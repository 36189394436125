@use "./colors.scss" as c;

.container {
  width: 100%;
  height: 100%;
}

.cursorHover {
  transform: scale(0.4);
}

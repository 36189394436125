@use "../style/colors.scss" as c;
.container {
  width: 100%;
  padding: 64px;

  .stack {
    width: 100%;
    display: flex;
    justify-content: center;
    .stackItems {
      color: c.$grey;
      padding: 48px 0;
      text-transform: uppercase;
      display: block;
      text-align: center;
      border-bottom: 2px c.$green solid;
      max-width: 600px;
    }
  }

  .buttonLinks {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .comingSoon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
    font-size: 60px;
    font-weight: 800;
    color: c.$grey-alpha;
  }

  .headerContainer {
    width: 100%;
    height: 500px;

    .heading {
      position: absolute;
      left: 0;
      right: 0;
      top: 58%;
      transform: translateY(-30%);
      margin-left: auto;
      margin-right: auto;
      z-index: 1;

      h1,
      h6 {
        color: white;
      }

      h6 {
        display: block;
        text-align: center;
      }

      h1 {
        display: block;
        text-align: center;
        margin-bottom: 16px;
      }
    }

    &::after {
      width: 100%;
      height: 100%;
      position: relative;
      content: "";
      display: block;
      top: -100%;
      left: 0;
      right: 0;
      bottom: 0;
      background: c.$background-alpha;
    }
  }
}

.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 124px;

  h5 {
    position: relative;
    display: block;
    width: max-content;
    color: c.$grey;
    margin: 48px 0;

    &::before {
      content: " ";
      height: 50%;
      width: 110%;
      display: block;
      position: absolute;
      top: 70%;
      left: -5%;
      background: c.$green-alpha;
      z-index: -1;
    }
  }

  p {
    margin: 48px 0;
  }
}

@media (max-width: 992px) {
  .container {
    padding: 0 16px;

    .content {
      padding: 0 80px;
    }

    .headerContainer {
      .heading {
        top: 50%;
      }
    }
  }
}

@media (max-width: 720px) {
  .container {
    padding: 0;

    .content {
      padding: 0 48px;
    }
  }
}

@media (max-width: 480px) {
  .container {
    padding: 0;

    .content {
      padding: 0 16px;
    }
  }
}

@use "../style/colors.scss" as c;

.container {
  width: 100%;
  padding: 0 64px;

  .bigText {
    text-transform: uppercase;
    z-index: -1;
    font-size: 160px;
    position: absolute;
    top: 90px;
    right: 8px;
    font-weight: 800;
    color: c.$green-alpha;
    animation: fadeIn 0.5s ease-in;
  }

  .row {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 0;
    margin-top: 200px;
  }

  .headshot {
    max-width: 120%;
    object-fit: cover;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    p {
      margin: 16px 0;
    }
  }

  .gallery {
    padding: 64px 0;

    h4 {
      position: relative;
      display: inline;
      width: max-content;
      color: c.$grey;

      &::before {
        content: " ";
        height: 50%;
        width: 110%;
        display: block;
        position: absolute;
        top: 70%;
        left: -5%;
        background: c.$green-alpha;
        z-index: -1;
      }
    }

    .tiles {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      margin: 48px 0;

      img {
        padding: 10px 0;
        width: 100%;
        overflow: hidden;
        object-fit: contain;
      }
    }
  }

  .socials {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding-top: 16px;

    p {
      color: c.$grey;
    }

    .social {
      display: flex;
      max-width: max-content;
      align-items: center;
      justify-content: center;
      cursor: none;
    }

    img {
      width: 24px;
      margin-right: 8px;
    }

    a {
      position: relative;
      display: inline;
      padding: 4px 16px;
      color: black;

      &:hover {
        background-color: transparent;
      }

      &::before {
        content: " ";
        height: 50%;
        width: 80%;
        display: block;
        position: absolute;
        top: 50%;
        left: 10%;
        background: rgba(114, 179, 116, 0.2);
        z-index: -1;
        transform: scaleX(0);
        transform-origin: bottom right;
        transition: transform 0.3s ease-in;
      }

      &:hover::before {
        transform: scaleX(1);
        transform-origin: bottom left;
      }
    }
  }
}

// tango fly in index.scss

@media (max-width: 992px) {
  .container {
    padding: 0 8px;

    .bigText {
      font-size: 130px;
    }

    .row {
      margin-top: 160px;
    }
  }
}

@media (max-width: 770px) {
  .container {
    .bigText {
      font-size: 100px;
    }
    .row {
      margin-top: 130px;
    }
    .socials {
      p {
        display: none;
        visibility: hidden;
      }
    }
  }
}

@media (max-width: 600px) {
  .container {
    .bigText {
      font-size: 60px;
    }
    .bigText {
      position: relative;
      text-align: center;
      margin: 0;
      top: 0;
    }

    .socials {
      flex-direction: column;
    }
    .row {
      margin-top: 0;
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

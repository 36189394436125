@use "../style/colors.scss" as c;

.container {
  width: 100%;
  padding-top: 64px;

  .row {
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap;
    width: 100%;
    padding-left: 64px;
  }

  .col {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .bigText {
    width: 100%;
    text-transform: uppercase;
    text-align: right;
    z-index: 2;
    font-size: 160px;
    font-weight: 800;
    color: c.$green-alpha;
    animation: fadeIn 0.5s ease-in;
  }

  .imgCol {
    display: inline;
    position: relative;
    text-align: center;

    &::before {
      content: " ";
      height: 35%;
      width: 100%;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      background: c.$grey-alpha;
      z-index: -1;
      transform: scaleX(1) rotate(-25deg);
      transform-origin: bottom right;
      transition: transform 0.3s ease-in;
      border-radius: 50%;
    }
  }

  img {
    width: 60%;
  }

  .content {
    display: flex;
    flex-direction: column;
    padding: 64px;

    .tiles {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin: 48px 0;
      width: 100%;

      img {
        padding: 10px 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

    .designContent {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 500px;

      .comingSoon {
        font-size: 60px;
        font-weight: 800;
        color: c.$grey-alpha;
      }
    }

    h4 {
      position: relative;
      display: inline;
      width: max-content;
      color: c.$grey;
      margin-bottom: 24px;

      &::before {
        content: " ";
        height: 50%;
        width: 110%;
        display: block;
        position: absolute;
        top: 70%;
        left: -5%;
        background: c.$green-alpha;
        z-index: -1;
        transform-origin: bottom left;
        transition: transform 0.3s ease;
      }
    }
  }
}

@media (max-width: 992px) {
  .container {
    .bigText {
      font-size: 110px;
    }

    .row {
      padding-left: 0;
    }
  }
}

@media (max-width: 768px) {
  .container {
    .content {
      padding: 0 8px;
    }
    .bigText {
      text-align: center;
      font-size: 80px;
    }
  }
}

@media (max-width: 480px) {
  .container .bigText {
    font-size: 40px;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@use "../../style/colors.scss" as c;
.sticky {
  display: block;
  position: fixed;
  width: 20%;
  top: 148px;
  left: 24px;
  max-height: calc(100vh - 40px);
  overflow: auto;
  padding-left: 8px;
  border-left: 1px solid c.$grey;
  text-transform: uppercase;

  ul li {
    margin: 16px 0;
    a {
      display: block;
      padding: 4px;
      color: c.$grey;
      transition-duration: 0.3s;
      transform-origin: left;
    }
  }
}

@media (max-width: 992px) {
  .sticky {
    display: none;
  }
}

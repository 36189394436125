@use "../style/colors.scss" as c;

.container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 64px 0;

  button {
    margin-bottom: 64px;

    a {
      color: white;
    }
  }

  img {
    width: 60%;
    border-radius: 5px;
    transition-duration: 0.3s;
    border: 1px solid c.$grey-alpha;

    &:hover {
      transform: scale(1.01);
      transition-duration: 0.3s;
    }
  }
}

@media (max-width: 780px) {
  .container img {
    width: 80%;
  }
}
